<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/security-privacy" parent-url="安全隐私" current-url="零信任" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/img_6.png" />
          <div class="about">
            <div class="title">零信任</div>
            <div class="content">
              零信任代表了新一代的网络安全防护理念，它的关键在于打破默认的“信任”，用一句通俗的话来概括，就是“持续验证，永不信任”。默认不信任企业网络内外的任何人、设备和系统，基于身份认证和授权重新构建访问控制的信任基础，从而确保身份可信、设备可信、应用可信和链路可信。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">
				<div class="row" data-aos="fade-up">
					<div class="col-lg-12">
						<div class="section-title">
							<h3 class="title color-gray">亿格云</h3>
						</div>
					</div>
					<div class="col-lg-12 text-center">
						<img class="company-logo" src="@/assets/images/brand/brand-42.png">
						<div class="company-info">
							我们专注为企业提供专业的办公安全一体化解决方案。推出我们的深度合作方亿格云基于零信任概念与SASE架构发布办公安全一体化产品——云枢。这款产品颠覆了传统的安全产品架构，可以实现安全上云，为企业用户带来更高效、简单、安全的办公服务。
						</div>
					</div>
				</div>

        <Separator style="margin: 80px 0;"/>

				<div class="row">
					<div class="col-lg-12">
						<div class="section-title" data-aos="fade-up">
							<h3 class="title color-gray">腾讯</h3>
						</div>
					</div>
					<div class="col-lg-12 text-center">
						<img class="company-logo" src="@/assets/images/brand/brand-41.png">
						<div class="company-info">
							iOA是腾讯零信任安全管理系统，2020年6月，腾讯正式发布自研零信任安全管理系统iOA5.0版本，采用基于可信身份、可信设备、可信应用、可信链路授予访问权限，并强制所有访问都必须经过认证、授权和加密，助力企业员工位于何处(Anywhere)、在何时(Anytime)使用何设备(Any device)都可安全地访问授权资源以处理任何业务（Any work）的新型“4A办公”方式，已在政府、金融、医疗、交通等多个行业领域应用落地。
						</div>
					</div>
				</div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">持安</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-43.png">
            <div class="company-info">
              持安远望办公安全平台将零信任架构融入企业信息化基础设施当中，在传统网络之上，构建一个基于身份的零信任网络。让零信任平台承载业务，所有的安全能力均为平台化的零信任产品提供，而业务无需关注安全，无需做二次开发，普通员工不用关心自己所处位置，实现无感知安全办公，兼顾安全与效率。平台使用独立部署模式，可以支持本地化部署，也可以支持云端部署，或是与企业的K8S，容器环境融合，实现全场景的部署和使用。持安自处研发高可用架构，可收敛所有访问入口，分布式就近访问，低延时，高传输性能。
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
